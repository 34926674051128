


















import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules'

@Component
export default class Logs extends Vue {
  @UserModule.State currentUser

  get rootLogsTabs() {
    return [
      {
        name: 'Account Transfer',
        component: () => import('@/components/Logs/AccountTransferLogs.vue'),
        icon: 'RepeatIcon',
        isActive: true
      },
    ]
  }
}
